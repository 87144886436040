<template>
    <div>
        <Header :id="id"></Header>
        <div class="prod">
          <div class="prod2">
            <div class="pleft">
              <div class="ltit">
                <div>{{ $t('pcategories') }}</div>
              </div>
              <div class="lcate">
                <a-menu mode="inline" :open-keys="openKeys" style="width: 256px" @openChange="onOpenChange">
                  <a-sub-menu v-for="(item,index) in cates" :key="index">
                    <span slot="title"><span>{{item.categoryName}}</span></span>
                    <a-menu-item  v-for="(vo,key) in item.childCategory" :key="key" @click="changeChildId(vo.categoryId,item.categoryId)">
                      {{vo.categoryName}}
                    </a-menu-item>
                  </a-sub-menu>
                </a-menu>
              </div>
            </div>
            <div class="pright">
              <div class="rtit">
                <div>{{ $t('products.list') }}</div>
                <!-- <div>1/200 &nbsp;<a-button> &lt; </a-button>&nbsp;&nbsp;<a-button> &gt; </a-button></div> -->
              </div>
              <div class="ringo" v-for="(item,index) in list" :key="index">
                <div class="rimg">
                  <img :src="item.thumb" alt="">
                </div>
                <div class="rtxt">
                  <div class="rtxt1">{{item.productName}}</div>
                  <div class="rtxt2">
                    <div class="rleft2">FOB Price:</div>
                    <div class="rright2">{{item.minPrice}} {{item.maxPrice}}</div>
                  </div>
                  <div class="rtxt3">
                    <div class="rleft3">Min. Order:</div>
                    <div class="rright3">{{item.minOrder}}</div>
                  </div>
                  <div class="rtxt4">
                    <div class="rleft4">About Us:</div>
                    <div class="rright4">{{item.companyName}}</div>
                  </div>
                  <div class="rtxt4">
                    <div class="rleft4">Region:</div>
                    <div class="rright4">{{item.country}}</div>
                  </div>
                  <div class="rtxt4">
                    <div class="rleft4">At:</div>
                    <div class="rright4">{{item.exhibitionName}}</div>
                  </div>
                  <div class="rtxt6">
                    <div class="rtxt5">{{ $t('contact.now') }}</div>
                    <div class="rheart red" v-if="item.isLike == 1" @click="unlike(item.productId)"><a-icon type="heart" /></div>
                    <div class="rheart" v-else @click="like(item.productId)"><a-icon type="heart" /></div>
                  </div>
                </div>
              </div>
              <!-- 分页 -->
              <div class="page">
                  <a-pagination
                    showQuickJumper
                    :defaultCurrent="pagination.defaultCurrent"
                    :defaultPageSize="pagination.defaultPageSize"
                    :total="pagination.total"
                    @change="onChange"
                    @showSizeChange="onShowSizeChange"
                  />
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "../components/Footer";

export default {
  name: "Products",
  inject: ['reload'], // 注入依赖
  components: {
    Footer,
    Header,
  },
  data() {
    return {
      rootSubmenuKeys: ['sub1', 'sub2', 'sub4'],
      openKeys: ['sub1'],
      list: [],
      cates: [],
      id: this.$route.params.id,
      queryParam: {
        exhibitionId: this.$route.params.id,
        categoryId: '',
        childCategoryId: ''
      },
      pagination: {
        showQuickJumper: true,
        showSizeChanger: true,
        defaultCurrent: 1, // 默认当前页数
        defaultPageSize: 5, // 默认当前页显示数据的大小
        total: 0 // 总数，必须先有
      },
      token: localStorage.token
    }
  },
  mounted () {
    console.log(this.$route.params)
    if (this.token == '') {
      this.$layer.msg('请先登录');
      this.$router.go(-1)
    }
    this.getCate()
    this.getList()
  },
  methods: {
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    //分页
    onShowSizeChange (current, pageSize) {
      this.pagination.defaultCurrent = 1
      this.pagination.defaultPageSize = pageSize
      this.getList() // 显示列表的接口名称
    },
    onChange(current, size) {
      this.pagination.defaultCurrent = current
      this.pagination.defaultPageSize = size
      // 查数据
      this.getList()
    },
    // 获取分类
    getCate () {
       this.$common.fetchList('/exhibition/tree/category/lists?exhibitionId='+this.id, {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          result.forEach(item => {
            this.cates.push({
              categoryId: item.categoryId,	
              categoryName: item.categoryName,			
              childCategory: item.childCategory,
            })
          })
        }
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    },
    // 获取展会商品列表
    getList () {
      const params = {
        page: this.pagination.defaultCurrent,
        pagesize: this.pagination.defaultPageSize
      }
       this.$common.fetchList('/exhibition/product/lists', Object.assign({}, params, this.queryParam)).then(data => {
        if (data.code === 200) {
          const result = data.data
          const pagination = { ...this.pagination }
          pagination.total = result.totalInfo.total_items
          this.list = result.lists
          this.pagination = pagination
        }
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    },
    // 改变子级
    changeChildId (id,id2) {
      this.queryParam.childCategoryId = id
      this.queryParam.categoryId = id2
    },
    //收藏
    like(productId) {
      this.$common.handlePost('/index/product/like', {productId:productId}).then(data => {
      if (data.code === 200) {
          this.$layer.alert(data.msg);
          this.reload()
      }
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    },
    unlike (productId) {
      this.$common.handlePost('/index/product/unlike', {productId:productId}).then(data => {
      if (data.code === 200) {
          this.$layer.alert(data.msg);
          this.reload()
      }
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    }
  },
   watch: {
    'queryParam.childCategoryId' (newVal, oldVal) {
      this.getList()
    },
     '$route.params.categoryId' (newVal, oldVal) {
      this.getList()
    },
   }
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/products.less';
</style>